import './App.css';
import React from "react";
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  const initialState = {
        home:true,
        history:false,
        portfolio:false,
        publications:false,
        award:false,
        contact:false,
        achievements:false
    }

const [data, setdata]= useState(initialState)
const returnHome = (e) => {

            setdata({
                ...data,
               home: true,
               history:false,
               portfolio:false,
               publications:false,
               award:false,
               contact:false,
               achievements:false

            });

    }
const returnHistory = (e) => {

                setdata({
                    ...data,
                   home: false,
                   history:true,
                   portfolio:false,
                   publications:false,
                   award:false,
                   contact:false,
                   achievements:false,

                });

        }
        const returnPortfolio = (e) => {

                    setdata({
                        ...data,
                       home: false,
                       history:false,
                       portfolio:true,
                       publications:false,
                       award:false,
                       contact:false,
                       achievements:false

                    });

            }
            const returnPublication = (e) => {

                        setdata({
                            ...data,
                           home: false,
                           history:false,
                           portfolio:false,
                           publications:true,
                           award:false,
                           contact:false,
                           achievements:true

                        });

                }

                const returnAward = (e) => {

                            setdata({
                                ...data,
                               home: false,
                               history:false,
                               portfolio:false,
                               publications:false,
                               award:true,
                               contact:false,
                               achievements:true

                            });

                    }
                    const returnContact = (e) => {

                                setdata({
                                    ...data,
                                   home: false,
                                   history:false,
                                   portfolio:false,
                                   publications:false,
                                   award:false,
                                   contact:true,
                                   achievements:false

                                });

                        }



  return (
    <div className="">
      <Router>
        <div className="art-content">
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: "url(img/bg.jpg)"}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <div className="transition-fade" id="">
                <div id="scrollbar" className="art-scroll-frame" style={{overflowY: "scroll",height:"calc(100vh - 10px)"}}>
                    <div>
                      <Switch>
                          <Route exact path="/">
                            <Home />
                          </Route>
                          <Route path="/history">
                            <History />
                          </Route>
                          <Route path="/portfolio">
                            <Portfolio />
                          </Route>
                          <Route path="/publications">
                            <Publications />
                          </Route>
                          <Route path="/award">
                            <Award />
                          </Route>
                          <Route path="/contact">
                            <Contact />
                          </Route>
                        </Switch>
                      </div>
                </div>
            </div>
            <div className="art-menu-bar">
                <div className="art-menu-bar-frame">
                    <div className="art-menu-bar-header">
                        <b className="art-menu-bar-btn">
                          <span></span>
                        </b>
                    </div>

                    <div className="art-current-page">
                      {( data.home ? " Home" : "")}
                      {( data.history ? " History" : "")}
                      {( data.portfolio ? "Portfolio" : "")}
                      {( data.publications ? "Publications" : "")}
                      {( data.award ? "Honor & Award" : "")}
                      {( data.contact ? "Contact" : "")}
                    </div>

                    <div className="art-scroll-frame">
                        <nav id="">
                            <ul className="main-menu">
                                <li id="home" className={'menu-item' + ( data.home ? " current-menu-item" : "")}><Link to="/" onClick={returnHome}>Home</Link></li>

                                <li id="history" className={'menu-item' + ( data.history ? " current-menu-item" : "")}><Link to="/history" onClick={returnHistory}>History</Link></li>

                                <li id="portfolio" className={'menu-item' + ( data.portfolio ? " current-menu-item" : "")}><Link to="/portfolio" onClick={returnPortfolio}>Portfolio</Link></li>

                                <li id="publications" className={'menu-item' + ( data.publications ? " current-menu-item" : "")}><Link to="/publications" onClick={returnPublication}>Publications</Link></li>

                                <li id="award" className={'menu-item' + ( data.award ? " current-menu-item" : "")}><Link to="/award" onClick={returnAward}>Honor & Award</Link></li>
                                <li className="menu-item"><a target="_blank" rel="noreferrer" href="https://blog.hasibulhuq.com">Blog</a></li>

                                <li id="contact" className={'menu-item' + ( data.contact ? " current-menu-item" : "")}><Link to="/contact" onClick={returnContact}>Contact</Link></li>
                            </ul>
                        </nav>
                        <ul className="art-language-change">
                          <li className="art-active-lang"><a href="#.">EN</a></li>
                        </ul>
                    </div>
                </div>
            </div>
         </div>
      </Router>
    </div>
  );
}

function Home() {
  return (
    <div id="home">
        <div className="container-fluid">
            <div className="row p-60-0 p-lg-30-0 p-md-15-0">
                <div className="col-lg-12">
                    <div className="art-a art-banner" style={{backgroundImage: "url(img/bg.jpg)"}}>
                        <div className="art-banner-back"></div>
                        <div className="art-banner-dec"></div>
                        <div className="art-banner-overlay">
                            <div className="art-banner-title" style={{textAlign:"left"}}>
                                <h1 className="mb-15">Discover my Amazing <br/>Art Space!</h1>
                                <div className="art-lg-text art-code mb-25">&lt;<i>code</i>&gt; I <span className="txt-rotate" data-period="2000"
                        data-rotate='[ "build websites.", "develop software applications.", "write blogs.", "travel new palces." ]'></span>&lt;/<i>code</i>&gt;</div>
                                <div className="art-buttons-frame">
                                    <a target="_blank" rel="noreferrer" href="https://blog.hasibulhuq.com" className="art-btn art-btn-md"><span>Explore now</span></a>
                                </div>
                            </div>
                            <img src="img/face-2.png" className="art-banner-photo" alt="Md Hasibul Huq" style={{height:"360px"}}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-md-3 col-6">
                    <div className="art-counter-frame">
                        <div className="art-counter-box">
                            <span className="art-counter">5</span><span className="art-counter-plus">+</span>
                        </div>
                        <h6>Years Experience</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="art-counter-frame">
                        <div className="art-counter-box">
                            <span className="art-counter">4</span>
                        </div>
                        <h6>Country Visited</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="art-counter-frame">
                        <div className="art-counter-box">
                            <span className="art-counter">2</span>
                        </div>
                        <h6>Research papers</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="art-counter-frame">
                        <div className="art-counter-box">
                            <span className="art-counter">6</span>
                        </div>
                        <h6>Honors and Awards</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="art-a art-service-icon-box">
                        <div className="art-service-ib-content">
                            <h5 className="mb-15">Professional Summary</h5>
                            <div className="mb-15">
                              <p>Hello I am Hasibul Huq and I am a software engineer working on web application and web infrastructure. I have been developing professionally for 5 years but tinkering since a kid. I started in tech with freelancing services and part time postions during. College</p>
                              <p>I have a bit off a diverse job history. I have had position in Junior Software Developer, Junior Software Engineer, Sr. Officer Software Developer, Java Proggrammer and Technical Advisor. I can help everywhere in stack. I do think my most stongest skills are in Software engineering</p>
                              <p>Most of my position utilizeed agile development process. A day in my life may consist of: prepping web art, fixing some bugs, adding an api end points, desgining and developing a system.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="art-a art-service-icon-box">
                        <div className="art-service-ib-content">
                            <h5 className="mb-15">Personal Summary</h5>
                            <div className="mb-15">
                                <p>I am flexible as in I meet so many strangers and mix with so many cultures. Different languages are reaching my ears and sound like music that sometimes I don't understand.</p>
                                <p>I love to explore new places. Whenever get chance I make sure there is plan to visit somewhere. I love soft music. I think it relax the body and mind. I am kind of lazy. So I have alexa, Siri, Cortana and Bixi</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                      <div className="art-a art-service-icon-box">
                          <div className="art-service-ib-content">
                              <h5 className="mb-15">Love To Write Blog</h5>
                              <div className="mb-15">I love to write anything that comes to my mind and what is better place to do other than blog. Nothing right. You can check my blog website.</div>
                              <div className="art-buttons-frame"><a target="_blank" rel="noreferrer" href="https://blog.hasibulhuq.com" className="art-link art-color-link art-w-chevron">See My Blog</a></div>
                          </div>
                      </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="art-a art-service-icon-box">
                        <div className="art-service-ib-content">
                            <h5 className="mb-15">Love to do Coding</h5>
                            <div className="mb-15">Sometime to make your thinking process better you need to work on your logics. And I build codes to enrich my thinking process.</div>
                            <div className="art-buttons-frame"><a target="_blank" rel="noreferrer" href="https://github.com/Hasib-rafi1" className="art-link art-color-link art-w-chevron">Check Github</a></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="art-a art-service-icon-box">
                        <div className="art-service-ib-content">
                            <h5 className="mb-15">Love to Research</h5>
                            <div className="mb-15">I am not a proffesional reasarcher. But I love when there is something new that I can contribute. </div>
                            <div className="art-buttons-frame"><a target="_blank" rel="noreferrer" href="https://scholar.google.ca/citations?hl=en&user=KwDcj1cAAAAJ" className="art-link art-color-link art-w-chevron">Check My Research Profile</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-lg-3">
              <img className="art-brand" src="img/brands/zeniox.png" alt="brand"></img>
            </div>
            <div className="col-6 col-lg-3">
              <img className="art-brand" src="img/brands/dnet.png" alt="brand"></img>
            </div>
            <div className="col-6 col-lg-3">
              <img className="art-brand" src="img/brands/save.png" alt="brand"></img>
            </div>
            <div className="col-6 col-lg-3">
              <img className="art-brand" src="img/brands/aj.png" alt="brand"></img>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Footer />
        </div>
        <br/>
      </div>
  );
}
function History() {
  return (
    <div id="history">

        <div className="container-fluid">

          <div className="row p-30-0">



            <div className="col-lg-6">


              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Work History</h4>
                </div>
              </div>
              <div className="art-timeline">

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Zeniox</h5>
                        <div className="art-el-suptitle mb-15">Technical Advisor</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Aug 2020 - current</span>
                      </div>
                    </div>
                    <p>Solving mobile app and web app related bugs from api side.
Providing Technical Advise to the agents if required.Salesforce and ringcentral maintenance and ensure connectivity between tools.</p>
                  </div>
                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>DNET</h5>
                        <div className="art-el-suptitle mb-15">Java Programmer</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Sept 2018 - Dec 2018</span>
                      </div>
                    </div>
                    <p> – Developing and Designing System Architecture and Software.<br/>
– Gathering Client requirements and developing those<br/>
– Dealing with multi-level stakeholders including SCI and Govt. Officials.<br/>
– Working on parallel multiple projects of Govt.<br/>
– Developed a govt health information system for Bangladesh<br/>
• Techs : JAVA, Python, PostgreSQL, RestfulWeb services, JS, HTML, CSS, LINUX,
Scripting, GIT, Agile
                    </p>
                  </div>

                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Save The Children International</h5>
                        <div className="art-el-suptitle mb-15">Sr. Officer - Software Development</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Nov 2017 - Aug 2018</span>
                      </div>
                    </div>
                    <p>– Developing and Designing System Architecture and Software.<br/>
– Dealing with multi-level stakeholders including Govt. Officials.<br/>
– Developing private (Local) network applications using PHP (Codeigniter, Laravel,
and java)<br/>
– Managed more than 7 Supervise (Developer) & worked as a technical member of 2
procurement processes.<br/>
– Working on parallel two projects of Govt. funded by USAID and supported by
SCI(MaMoni HSS).<br/>
• Techs : PHP(Laravel,Codeigniter), React, HTML, CSS, JS, MySql, GIT, Agile</p>
                  </div>


                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>AMUJAMU</h5>
                        <div className="art-el-suptitle mb-15">Junior Software Engineer</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">May 2017 - Aug 2017</span>
                      </div>
                    </div>
                    <p> – Developing aWeb System using PHP (Symfony and Laravel). <br/>
– Supporting the Mobile application department by providing APIs.<br/>
– Requirement Collection from the business team.<br/>
• Techs : PHP(Laravel,Symfony), React, VUE, HTML, CSS, JS, MySql, Elastic Search,
Docker, Doctrine, Agile
                    </p>
                    <a target="_blank" rel="noreferrer" href="https://amujamu.com" className="art-link art-color-link art-w-chevron">Site</a>
                  </div>


                </div>
                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Prochito ITS</h5>
                        <div className="art-el-suptitle mb-15">Junior Software Engineer</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Mar 2016 - May 2017</span>
                      </div>
                    </div>
                    <p> – Developing software & web System for a third-party organization using PHP and
Python.<br/>
– Consulting 3rd party organizations. & Designed module-based architecture and
developing algorithms.<br/>
• Techs : PHP(Laravel,Symfony), Python, React, VUE, UnderscoreJS, HTML, CSS, JS,
MySql, Angular, Elastic Search, Docker, Doctrine
                    </p>
                    </div>


                </div>

              </div>

            </div>
            <div className="col-lg-6">


              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Education</h4>
                </div>
              </div>

              <div className="art-timeline art-gallery">
                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Concordia University</h5>
                        <div className="art-el-suptitle mb-15">Master's in Applied Computer Science</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Jan 2019 - Dec 2020</span>
                      </div>
                    </div>

                    <p>Completed Master's in Applied Computer Scinece in 2020 with a great experience of studying online during COVID-19. In these year learned Distributed programming, Some functional programming, Software architecture, Managing projects, Artificical Intelegnce, Machine Learning most importantly Algorithm and Networks</p>
                    <a data-fancybox="Certificate" href="files/msc.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>American International University-Bangladesh</h5>
                        <div className="art-el-suptitle mb-15">Bachelor of Computer Science and Engineering</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Sept 2012 - Feb 2017</span>
                      </div>
                    </div>
                    <p>Never follow anyone else’s path, unless you’re in the woods and you’re lost and you see a path and by all means you should follow that. Don’t give advice, it will come back and bite you in the ass. Don’t take anyone’s advice. So my advice to you is to be true to yourself and everything will be fine.</p>
                    <a data-fancybox="Certificate" href="files/bsc.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Milestone College</h5>
                        <div className="art-el-suptitle mb-15">HSC</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">March 2010 - March 2012</span>
                      </div>
                    </div>
                    <p>Nothing to say about my college life. It was horrible.</p>
                  </div>

                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>

                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Milestone School</h5>
                        <div className="art-el-suptitle mb-15">SSC</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Jan 2008 - Feb 2010</span>
                      </div>
                    </div>
                    <p>It was so so . But I started to explore the city by my own.</p>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>




        <div className="container-fluid">
          <Footer />
        </div>
        <br/>
      </div>

  );
}

function Footer() {
  return (
    <footer>
      <div>Copyright © 2021 Md. Hasibul Huq</div>
      <div><a href="https://hasibulhuq.com/" rel="noreferrer" target="_blank">Website</a></div>

    </footer>
  );
}

function Portfolio() {
  return (
    <div id="portfolio">
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Portfolio</h4>
                        </div>
                    </div>
                </div>
                <div className="art-grid art-grid-2-col art-gallery">
                    <div className="art-grid-item ">
                        <a data-fancybox="gallery" href="http://os.hasibulhuq.com/img/logo.png" className="art-a art-portfolio-item-frame art-horizontal">
                            <img src="http://os.hasibulhuq.com/img/logo.png" alt="item"/>

                    <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                  </a>
                  <div className="art-item-description">

                    <h5 className="mb-15">OS Scheduling</h5>
                    <div className="mb-15">Academic Project</div>

                    <a target="_blank" rel="noreferrer" href="http://os.hasibulhuq.com/" className="art-link art-color-link art-w-chevron">Go To The Site </a>
                  </div>

                </div>
                <div className="art-grid-item ">

                  <a data-fancybox="gallery" href="/img/works/aj.jpg" className="art-a art-portfolio-item-frame art-horizontal">

                    <img src="/img/works/aj.jpg" alt="item"/>

                    <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                  </a>
                  <div className="art-item-description">

                    <h5 className="mb-15">AMUJAMU</h5>
                    <div className="mb-15">A  project where i was the part of the core developing team.</div>

                    <a target="_blank" rel="noreferrer" href="https://amujamu.com/" className="art-link art-color-link art-w-chevron">Go To The Site </a>
                  </div>

                </div>
                <div className="art-grid-item ">

                  <a data-fancybox="gallery" href="/img/works/oh.png" className="art-a art-portfolio-item-frame art-horizontal">

                    <img src="/img/works/oh.png" alt="item"/>

                    <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                  </a>
                  <div className="art-item-description">

                    <h5 className="mb-15">Oh Tshirt</h5>
                    <div className="mb-15">A  project where i was the part of the core developing team.</div>

                    <a target="_blank" rel="noreferrer" href="https://ohtshirt.com/" className="art-link art-color-link art-w-chevron">Go To The Site </a>
                  </div>

                </div>

                <div className="art-grid-item ">

                  <a data-fancybox="gallery" href="/img/works/github.png" className="art-a art-portfolio-item-frame art-horizontal">

                    <img src="/img/works/github.png" alt="item"/>

                    <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                  </a>
                  <div className="art-item-description">

                    <h5 className="mb-15">Github Projects</h5>
                    <div className="mb-15">A list of projects i did for academic and fun purpose.</div>

                    <a target="_blank" rel="noreferrer" href="https://github.com/Hasib-rafi1" className="art-link art-color-link art-w-chevron">Go To The Site </a>
                  </div>

                </div>
              </div>

            </div>

          </div>



          <div className="container-fluid">
            <Footer />
          </div>

        </div>



  );
}

function Publications() {
  return (
    <div id="publications">

        <div className="container-fluid">

          <div className="row p-30-0">



            <div className="col-lg-12">


              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Publications</h4>
                </div>
              </div>
              <div className="art-timeline">

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Comparison of Machine Learning Algorithms to Recognize Human Activities from Images and Videos Using Pose Estimation and Feature Extraction</h5>
                        <div className="art-el-suptitle mb-15">Proceedings of the Future Technologies Conference. Publisher:Springer, Cham</div>
                        <div className="art-el-suptitle mb-15">Authors: Md Hasibul Huq, Mohammed Alnakli, Zakiya Jafrin, Tanjima Nasreen Jenia</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">2020/11/5</span>
                      </div>
                    </div>
                    <p>In the last few years, there have been a lot of attempts to solve human activity recognition. Few of them are trying to solve the problem by using video streams or images. In machine learning algorithms, the conventional approach for classifying images or frames is to compare them as pixel by pixel which often leads to wrong assumptions. To solve this problem, pose estimation and visual feature extraction plays a vital role. In this research, these two techniques are applied to six different machine learning algorithms for image classification, and furthermore, a comparative analysis is being conducted among them.</p>
                    <a data-fancybox="diplome" href="files/con1.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>
                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Machine Translation from Natural Language to Code Using Long-Short Term Memory</h5>
                        <div className="art-el-suptitle mb-15">Proceedings of the Future Technologies Conference (FTC) 2019: Volume 1. Publisher:Springer Nature</div>
                        <div className="art-el-suptitle mb-15">Authors: KM Tahsin Hassan Rahit, Rashidul Hasan Nabil, Md Hasibul Huq</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">2020/11/5</span>
                      </div>
                    </div>
                    <p>Making computer programming language more understandable and easy for the human is a longstanding problem. From assembly language to present day’s object-oriented programming, concepts came to make programming easier so that a programmer can focus on the logic and the architecture rather than the code and language itself. To go a step further in this journey of removing human-computer language barrier, this paper proposes machine learning approach using Recurrent Neural Network (RNN) and Long-Short Term Memory (LSTM) to convert human language into programming language code. The programmer will write expressions for codes in layman’s language, and the machine learning model will translate it to the targeted programming language. The proposed approach yields result with 74.40% accuracy. This can be further improved by incorporating additional techniques, which are also discussed in this paper.</p>
                    <a data-fancybox="diplome" href="files/con2.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>

            </div>


            </div>

          </div>

        </div>




        <div className="container-fluid">
          <Footer />
          <br/>
        </div>
        <br/>
      </div>

  );
}
function Award() {
  return (
    <div id="award">

        <div className="container-fluid">

          <div className="row p-30-0">



            <div className="col-lg-12">


              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Honors And Award</h4>
                </div>
              </div>
              <div className="art-timeline">

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Dr. Anwarul Abedin Leadership Award</h5>
                        <div className="art-el-suptitle mb-15">American International Univeristy- Bangladesh</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">February 2017</span>
                      </div>
                    </div>
                    <p>Dr. Anwarul Abedin’s Leadership AWARD (formerly known as the Chairman’s Award): This Leadership Award is presented to deserving students in recognition of the outstanding voluntary efforts, exemplary performance, and noteworthy achievements and contributions in the various categories of co-curricular activities. The student must be a *regular student and with a minimum CGPA is 3.20 on a scale of 4.00. </p>
                    <a data-fancybox="diplome" href="files/aiublac.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>
                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>Leadership Award for Voluntary</h5>
                        <div className="art-el-suptitle mb-15">AIUB Computer Club</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">April 2016</span>
                      </div>
                    </div>
                    <p>Serving a club from 2012 to 2016. Leading from the front and contributing in the development of the club and other students. The award is provided to aprriciate the work done by the individual.</p>
                    <a data-fancybox="diplome" href="files/vcacc.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>

                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>


                  <div className="art-a art-timeline-content">
                    <div className="art-card-header">
                      <div className="art-left-side">
                        <h5>CS FEST 2015 Voluntary Certificate</h5>
                        <div className="art-el-suptitle mb-15">AIUB Computer Club</div>
                      </div>
                      <div className="art-right-side">
                        <span className="art-date">Sept 2015</span>
                      </div>
                    </div>
                    <p>Arranging a nation wide tech event to promote Computer Science and spreading the joy among the students of Colleges and Univeristies.</p>
                    <a data-fancybox="diplome" href="files/csfestvc2015.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                  </div>
                </div>
                <div className="art-timeline-item">
                  <div className="art-timeline-mark-light"></div>
                  <div className="art-timeline-mark"></div>
                <div className="art-a art-timeline-content">
                  <div className="art-card-header">
                    <div className="art-left-side">
                      <h5>Judgeing the Programming contest in CS FEST 2014</h5>
                      <div className="art-el-suptitle mb-15">AIUB Computer Club</div>
                    </div>
                    <div className="art-right-side">
                      <span className="art-date">Sept 2014</span>
                    </div>
                  </div>
                  <p>This is the certification for giving the time for judgeing the inter University programming contest.</p>
                  <a data-fancybox="diplome" href="files/judgecsfest2014.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                </div>
              </div>
              <div className="art-timeline-item">
                <div className="art-timeline-mark-light"></div>
                <div className="art-timeline-mark"></div>
              <div className="art-a art-timeline-content">
                <div className="art-card-header">
                  <div className="art-left-side">
                    <h5>CS FEST 2014 Voluntary Certificate</h5>
                    <div className="art-el-suptitle mb-15">AIUB Computer Club</div>
                  </div>
                  <div className="art-right-side">
                    <span className="art-date">Sept 2014</span>
                  </div>
                </div>
                <p>Arranging a nation wide tech event to promote Computer Science and spreading the joy among the students of Colleges and Univeristies.</p>
                <a data-fancybox="diplome" href="files/csfestvc2014.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
              </div>
            </div>

            <div className="art-timeline-item">
              <div className="art-timeline-mark-light"></div>
              <div className="art-timeline-mark"></div>
            <div className="art-a art-timeline-content">
              <div className="art-card-header">
                <div className="art-left-side">
                  <h5>Certification of Participation in Google Developer Group Mapup</h5>
                  <div className="art-el-suptitle mb-15">Google Developer Group </div>
                </div>
                <div className="art-right-side">
                  <span className="art-date">March 2014</span>
                </div>
              </div>
              <p>Participated google mapping of dhaka. Arranged by google developer group.</p>
              <a data-fancybox="diplome" href="files/gdg.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
            </div>
          </div>
            </div>


            </div>

          </div>

        </div>




        <div className="container-fluid">
          <Footer />
        </div>
        <br/>
      </div>
  );
}
function Contact() {
  return (
    <div id="contact">

        <div className="container-fluid">

          <div className="row p-30-0">

            <div className="col-lg-12">

              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Contact information</h4>
                </div>
              </div>

            </div>
            <div className="col-lg-4">
              <div className="art-a art-card">
                <div className="art-table p-15-15">
                  <ul>
                    <li>
                      <h6>Country:</h6><span>Canada</span>
                    </li>
                    <li>
                      <h6>City:</h6><span>Montreal</span>
                    </li>

                    <li>
                      <h6>Streat:</h6><span>Plamondon Ave.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="art-a art-card">
                <div className="art-table p-15-15">
                  <ul>
                    <li>
                      <h6>Email:</h6><span>hasibulhuq.rafi@gmail.com</span>
                    </li>
                    <li>
                      <h6>Messanger:</h6><span>hasib.rafi.7</span>
                    </li>
                    <li>
                      <h6>Skype:</h6><span>hasib.rafi1</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="art-a art-card">
                <div className="art-table p-15-15">
                  <ul>
                    <li>
                      <h6>Office:</h6><span>1-888-779-7646</span>
                    </li>
                    <li>
                      <h6>Personal:</h6><span>Please Email</span>
                    </li>
                    <li>
                      <h6>Home:</h6><span>Please Email</span>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

            <div className="col-lg-12">

              <div className="art-section-title">

                <div className="art-title-frame">

                  <h4>Get in touch</h4>
                </div>

              </div>
              <div className="art-a art-card">


                <form id="form" className="art-contact-form" action="mailto:hasibulhuq.rafi@gmail.com" method="post" encType="text/plain">

                  <div className="art-form-field">

                    <input id="name" name="name" className="art-input" type="text" placeholder="Name" required/>

                    <label htmlFor="name"><i className="fas fa-user"></i></label>
                  </div>
                  <div className="art-form-field">

                    <input id="email" name="mail" className="art-input" type="email" placeholder="Email" required/>

                    <label htmlFor="email"><i className="fas fa-at"></i></label>
                  </div>

                  <div className="art-form-field">

                    <textarea id="message" name="comment" className="art-input" placeholder="Message" required></textarea>

                    <label htmlFor="message"><i className="far fa-envelope"></i></label>
                  </div>
                  <div className="art-submit-frame">
                    <button className="art-btn art-btn-md art-submit" type="submit"><span>Send message</span></button>

                    <div className="art-success">Success <i className="fas fa-check"></i></div>
                  </div>
                </form>

              </div>

            </div>

          </div>

        </div>



        <div className="container-fluid">
          <Footer />
        </div>
        <br/>

      </div>
  );
}
export default App;
